// src/api/authApi.js
import { axiosInstance } from "./axiosConfig";

export const get_static_images = async (image_type, page = null) => {
  try {
    const response = await axiosInstance.get(`/files/images/`, {
      params: {
        file_type: image_type,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const get_face_images = async (page = null) => {
  const images = await get_static_images("FACE");
  return images.data;
};

export const get_full_model_images = async (page = null) => {
  const images = await get_static_images("MODEL_FULL");
  return images.data;
};

export const get_upper_half_model_images = async (page = null) => {
  const images = await get_static_images("MODEL_UPPER_HALF");
  return images.data;
};

export const get_lower_half_model_images = async (page = null) => {
  const images = await get_static_images("MODEL_LOWER_HALF");
  return images.data;
};

export const get_background_images = async (page = null) => {
  const images = await get_static_images("BACKGROUND");
  return images.data;
};
