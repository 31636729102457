import React, { createContext, useState, useEffect } from "react";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [mode, setMode] = useState(() => {
    return localStorage.getItem("theme-mode") || "light-mode";
  });

  const toggleLight = () => {
    setMode("light-mode");
  };

  const toggleDark = () => {
    setMode("dark-mode");
  };

  useEffect(() => {
    document.body.className = mode;
    localStorage.setItem("theme-mode", mode);
  }, [mode]);

  return (
    <ThemeContext.Provider value={{ mode, toggleLight, toggleDark }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
