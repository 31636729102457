import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/images/logo.svg";
import { ThemeContext } from "../ThemeContext";

const Forgot = () => {
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const navigate = useNavigate();
  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // Handle theme toggle
  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      toggleDark();
      localStorage.setItem("theme", "dark-mode");
    } else {
      toggleLight();
      localStorage.setItem("theme", "light-mode");
    }
  };

  // Set initial theme based on local storage
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light-mode";
    if (savedTheme === "dark-mode") {
      toggleDark();
    } else {
      toggleLight();
    }
  }, [toggleLight, toggleDark]);

  // Handle login form submission
  const handleLogin = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setAlertMessage("Password reset mail sent");
    setAlertVisible(true);
    // navigate("/dashboard/banner");
  };

  useEffect(() => {
    let timer;
    if (alertVisible) {
      timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000); // Hide alert after 5 seconds
    }
    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, [alertVisible]);

  // Toggle password visibility
  const toggleLoginPasswordVisibility = () => {
    setShowLoginPassword((prevShowPassword) => !prevShowPassword);
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <React.Fragment>
      <div className="container-fluid footer_section">
        <div className="row row-cols-1 row-cols-md-2">
          <div
            className="col-md-6 col-lg-6 p-0"
            style={{ position: "relative", background: "#fff" }}
          >
            <div className="login d-flex h-100">
              <div className="container m-auto">
                <div className="row">
                  <div className="col-lg-10 col-xl-10 flex-wrap align-items-center py-5 mx-auto">
                    <div className="d-flex justify-content-end align-items-center theme_toggle m-3">
                      <div className="d-flex align-items-center me-2">
                        <span className="theme_heading">Mode:</span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id="checkbox"
                          checked={mode === "dark-mode"}
                          onChange={handleThemeToggle}
                        />
                        <label htmlFor="checkbox" className="checkbox-label">
                          <i className="fas fa-moon"></i>
                          <i className="fas fa-sun"></i>
                          <span className="ball"></span>
                        </label>
                      </div>
                    </div>
                    {!isSubmitted && (
                      <div className="d-flex text-center">
                        <div className="w-100 mb-2">
                          <h3 className="loginpage-heading">
                            Forgot Password!
                          </h3>
                          <p className="text-center">
                            Enter the email address associated with your account
                            and we'll send you a link to reset your password.
                          </p>
                        </div>
                      </div>
                    )}
                    {isSubmitted ? (
                      <div className="text-center">
                        <h3 className="loginpage-heading">
                          Password Reset Email Sent
                        </h3>
                        <p>
                          A password reset link has been sent to{" "}
                          <span className=" text-success">{email}</span>. Please
                          check your email for further instructions.
                        </p>
                        <div className="field">
                          <a
                            href="/"
                            className="btn-block w-100 login_button"
                            type="submit"
                          >
                            Sign in
                          </a>
                        </div>
                      </div>
                    ) : (
                      <form
                        action="#"
                        className="signin-form"
                        onSubmit={handleLogin}
                        autoComplete="off"
                      >
                        <div className="form-group mb-3 mt-4">
                          <label htmlFor="email" className="label pb-2">
                            Email
                          </label>
                          <input
                            className="form-control input_field form-controls-field"
                            type="email"
                            id="email"
                            placeholder="Email Address"
                            required
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="field">
                          <button
                            className="btn-block w-100 login_button"
                            type="submit"
                          >
                            Continue
                          </button>
                        </div>
                        <div className="signup-link mt-3 text-center">
                          <span>Don't have an account ? </span>
                          <Link to="/" style={{ color: "#EA454C" }}>
                            Sign In
                          </Link>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 p-0 responsive_div">
            <div className="login-image h-100">
              <img
                src={logo}
                className="img-fluid logo"
                alt="no_img"
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>

        {alertVisible && (
          <div
            style={{ zIndex: "9999" }}
            className={`alert alert-warning alert-dismissible fade show position-fixed top-0 end-0 m-3 `}
            role="alert"
          >
            {alertMessage}
            <button
              type="button"
              className="btn-close"
              onClick={() => setAlertVisible(false)}
              aria-label="Close"
              style={{ border: "none", height: "15px", width: "15px" }}
            ></button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Forgot;
