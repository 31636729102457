// src/api/authApi.js
import {
  axiosInstance,
  axiosInstanceUnAuthenticated,
  API_BASE_URL,
} from "./axiosConfig";
import { getCookie, setCookie, deleteCookie } from "./utils/cookies";

export const login = async (credentials) => {
  try {
    const response = await axiosInstanceUnAuthenticated.post(
      `${API_BASE_URL}/usermgmt/knox/login/`,
      credentials
    );
    setCookie("token", response.data.token);
    console.log("token stored in cookie");
    return response;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return { status: 400, detail: error.response.data };
    }
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const register = async (userData) => {
  try {
    const response = await axiosInstanceUnAuthenticated.post(
      `${API_BASE_URL}/usermgmt/signup/`,
      userData
    );
    return response.status == 200;
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("Registration Failed!");
  }
};

export const getProfile = async () => {
  try {
    const response = await axiosInstance.get("/usermgmt/user_profile/");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const logOut = async () => {
  try {
    const response = await axiosInstance.post("/usermgmt/knox/logout/");
    deleteCookie("token");
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const resendAccountVerificationEmail = async () => {
  try {
    const response = await axiosInstance.post(
      "/usermgmt/resend_email_verification/"
    );
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await axiosInstance.post("/usermgmt/verify_email/", {
      token: token,
    });
    return response;
  } catch (e) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
