import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/images/logo.svg";
import { ThemeContext } from "../ThemeContext";

export const PasswordReset = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [popup, setPopup] = useState({
    show: false,
    message: "",
    isSuccess: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [tokenError, setTokenError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    console.log("token: ", token);

    if (!token) {
      setTokenError("No token found in URL");
      setIsLoading(false);
      return;
    }

    // Validate token
    const validateToken = async () => {
      try {
        const response = await fetch(`http://localhost:3001/reset_password`);
        if (response.ok) {
          setIsLoading(false);
        } else {
          setTokenError("Invalid or expired token");
          setIsLoading(false);
        }
      } catch (error) {
        setTokenError("Error validating token");
        setIsLoading(false);
      }
    };

    validateToken();
  }, [location]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (password1 !== password2) {
      setPopup({
        show: true,
        message: "Passwords do not match!",
        isSuccess: false,
      });
      return;
    }

    try {
      const response = await fetch("http://localhost:3001/reset_password", {
        method: "GET", // Changed to GET for json-server
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status === "success") {
          setPopup({ show: true, message: data.message, isSuccess: true });
          setTimeout(() => navigate("/"), 2000); // Redirect to login after 2 seconds
        } else {
          setPopup({
            show: true,
            message: data.message || "Password reset failed",
            isSuccess: false,
          });
        }
      } else {
        setPopup({
          show: true,
          message: "Error resetting password",
          isSuccess: false,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setPopup({
        show: true,
        message: "Error resetting password",
        isSuccess: false,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Handle theme toggle
  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      toggleDark();
      localStorage.setItem("theme", "dark-mode");
    } else {
      toggleLight();
      localStorage.setItem("theme", "light-mode");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (tokenError) {
    return (
      <div className=" my-64 text-center">
        <h3 className="loginpage-heading">Error</h3>
        <p className="text-danger">{tokenError}</p>
        <div
          className=" flex justify-center"
          onClick={() => navigate("/forgot")}
        >
          <button className="btn-block  login_button" type="submit">
            Return to Forgot Password
          </button>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={`container-fluid footer_section ${mode}`}>
        <div className="row row-cols-1 row-cols-md-2">
          <div
            className="col-md-6 col-lg-6 p-0"
            style={{ position: "relative", background: "#fff" }}
          >
            <div className="login d-flex h-100">
              <div className="container m-auto">
                <div className="row">
                  <div className="col-lg-10 col-xl-10 flex-wrap align-items-center py-5 mx-auto">
                    <div className="d-flex justify-content-end align-items-center theme_toggle m-3">
                      <div className="d-flex align-items-center me-2">
                        <span className="theme_heading">Mode:</span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id="checkbox"
                          checked={mode === "dark-mode"}
                          onChange={handleThemeToggle}
                        />
                        <label htmlFor="checkbox" className="checkbox-label">
                          <i className="fas fa-moon"></i>
                          <i className="fas fa-sun"></i>
                          <span className="ball"></span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex text-center">
                      <div className="w-100 mb-2">
                        <h3 className="loginpage-heading">Reset Password</h3>
                        <p className="text-center">
                          Enter your new password below.
                        </p>
                      </div>
                    </div>
                    <form onSubmit={handlePasswordReset} autoComplete="off">
                      <div className="form-group mb-3 mt-4">
                        <label htmlFor="password1" className="label pb-2">
                          New Password
                        </label>
                        <div className="password-input-wrapper">
                          <input
                            className="form-control input_field form-controls-field"
                            type={showPassword ? "text" : "password"}
                            id="password1"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            placeholder="Enter new password"
                            required
                          />
                          <i
                            className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} password-toggle`}
                            onClick={togglePasswordVisibility}
                          ></i>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="password2" className="label pb-2">
                          Confirm New Password
                        </label>
                        <div className="password-input-wrapper">
                          <input
                            className="form-control input_field form-controls-field"
                            type={showPassword ? "text" : "password"}
                            id="password2"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            placeholder="Confirm new password"
                            required
                          />
                        </div>
                      </div>
                      <div className="field">
                        <button
                          className="btn-block w-100 login_button"
                          type="submit"
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 p-0 responsive_div">
            <div className="login-image h-100">
              <img
                src={logo}
                className="img-fluid logo"
                alt="no_img"
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>
      </div>
      {popup.show && (
        <div
          style={{ zIndex: "9999" }}
          className={`alert alert-warning alert-dismissible fade show position-fixed top-0 end-0 m-3 ${popup.isSuccess ? "success" : "error"} `}
          role="alert"
        >
          <p>{popup.message}</p>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAlertVisible(false)}
            aria-label="Close"
            style={{ border: "none", height: "15px", width: "15px" }}
          ></button>
        </div>
      )}
    </React.Fragment>
  );
};
