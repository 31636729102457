import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext } from "../ThemeContext";
import logo from "../../assets/images/logo.svg";
import { verifyEmail } from "../../api/authApi";
import { PopupBox } from "../Utils/Popup.jsx";

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);

  const handleEmailVerify = async (token) => {
    if (!token) {
      setError("Token not found in URL");
      setIsLoading(false);
      setAlertVisible(true);
      return;
    }
    try {
      const response = await verifyEmail(token);
      setIsLoading(false);
      if (response.status === 200) {
        setIsValid(true);
        setError("Email verified successfully!");
        setTimeout(() => navigate("/dashboard/home"), 3000); // Redirect after 3 seconds
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (e) {
      setError(e.message || "Email verification failed. Please try again.");
      setIsLoading(false);
      setAlertVisible(true);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    handleEmailVerify(token);
  }, [location]);

  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      toggleDark();
      localStorage.setItem("theme", "dark-mode");
    } else {
      toggleLight();
      localStorage.setItem("theme", "light-mode");
    }
  };

  return (
    <div className={`container-fluid footer_section ${mode}`}>
      <div className="row row-cols-1 row-cols-md-2">
        <div
          className="col-md-6 col-lg-6 p-0"
          style={{ position: "relative", background: "#fff" }}
        >
          <div className="login d-flex h-100">
            <div className="container m-auto">
              <div className="row">
                <div className="col-lg-10 col-xl-10 flex-wrap align-items-center py-5 mx-auto">
                  <div className="d-flex justify-content-end align-items-center theme_toggle m-3">
                    <div className="d-flex align-items-center me-2">
                      <span className="theme_heading">Mode:</span>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="checkbox"
                        checked={mode === "dark-mode"}
                        onChange={handleThemeToggle}
                      />
                      <label htmlFor="checkbox" className="checkbox-label">
                        <i className="fas fa-moon"></i>
                        <i className="fas fa-sun"></i>
                        <span className="ball"></span>
                      </label>
                    </div>
                  </div>
                  <div className="text-center">
                    <h3 className="loginpage-heading">Verifying Your Account</h3>
                    {isLoading ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : isValid ? (
                      <p className="text-success">
                        {error} Redirecting to dashboard...
                      </p>
                    ) : (
                      <div className="my-8 text-center">
                        <p className="text-danger text-lg">{error}</p>
                        <div className="flex justify-center">
                          <button
                            className="btn-block login_button"
                            onClick={() => navigate("/forgot")}
                          >
                            Resend Verification Email
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 p-0 responsive_div">
          <div className="login-image h-100">
            <img
              src={logo}
              className="img-fluid logo"
              alt="logo"
              style={{ width: "200px" }}
            />
          </div>
        </div>
      </div>

      <PopupBox
        alertVisible={alertVisible}
        warningMessage={error}
        disableFunction={() => setAlertVisible(false)}
      />
    </div>
  );
};

export default VerifyEmail;