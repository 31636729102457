import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ShowIcon } from "../../assets/images/showicon.svg";
import { ReactComponent as HideIcon } from "../../assets/images/hideicon.svg";
import logo from "../../assets/images/logo.svg";
import { ThemeContext } from "../ThemeContext";
import { register } from "../../api/authApi";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password1: "",
    password2: "",
  });
  const [errors, setErrors] = useState({});
  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);
  const [signupSuccess, setSignupSuccess] = useState(false);


  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      toggleDark();
    } else {
      toggleLight();
    }
  };
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { first_name, last_name, email, password1, password2 } = formData;
    const formErrors = {};

    if (!first_name.trim()) {
      formErrors.first_name = "First Name is required";
    }
    if (!last_name.trim()) {
      formErrors.last_name = "Last Name is required";
    }

    if (!email.trim()) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email is not valid";
    }

    if (!password1) {
      formErrors.password1 = "Password is required";
    } else if (password1.length < 6) {
      formErrors.password1 = "Password must be at least 6 characters";
    }

    if (!password2) {
      formErrors.password2 = "Confirm password1 is required";
    } else if (password1 !== password2) {
      formErrors.password2 = "Passwords do not match";
    }

    return formErrors;
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await register(formData);
        if (response === true) {
          setSignupSuccess(true);
        } else {
          setErrors({ general: "An unexpected error occurred. Please try again." });
        }
      } catch (error) {
        setErrors({ general: error.message || "Failed to create account. Please try again." });
      }
    } else {
      setErrors(formErrors);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShow) => !prevShow);
  };

  useEffect(() => {
    document.body.classList.add("center-body");

    return () => {
      document.body.classList.remove("center-body");
    };
  }, []);

  if (signupSuccess) {
    return (
      <div className="bg-white">
          <div className="h-screen" style={{ position: "relative", background: "#fff" }}>
                  <div className="flex flex-col items-center py-64">
                    <div className="text-center">
                      <h3 className="loginpage-heading py-12  ">Account Created Successfully!</h3>
                      <p>Thank you for signing up. We've sent a verification email to your inbox.</p>
                      <p>Please check your email and click the verification link to activate your account.</p>
                    </div>
                    <div className="text-center mt-4">
                      <Link to="/" className="login_button hover:bg-yellow-500" style={{ width: "200px" }}>
                        Back to Login
                      </Link>
                    </div>
                  </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="container-fluid" style={{ background: "#fff" }}>
        <div className="row row-cols-1 row-cols-md-2">
          <div
            className="col-md-6 col-lg-6 p-0"
            style={{ position: "relative", background: "#fff" }}
          >
            <div className="login d-flex h-100">
              <div className="container m-auto">
                <div className="row">
                  <div className="col-lg-10 col-xl-10 flex-wrap align-items-center py-5 mx-auto">
                    <div className="d-flex justify-content-end align-items-center theme_toggle m-3">
                      <div className="d-flex align-items-center me-2">
                        <span className="theme_heading">Mode:</span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id="checkbox"
                          checked={mode === "dark-mode"}
                          onChange={handleThemeToggle}
                        />
                        <label htmlFor="checkbox" className="checkbox-label">
                          <i className="fas fa-moon"></i>
                          <i className="fas fa-sun"></i>
                          <span className="ball"></span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex text-center">
                      <div className="w-100">
                        <h3 className="loginpage-heading">Create an Account</h3>
                        <p>Please fill in the details to create an account.</p>
                      </div>
                    </div>
                    <form
                      onSubmit={handleSignup}
                      className="signin-form"
                      autoComplete="off"
                    >
                      <div className="form-group mb-2 mt-4">
                        <label htmlFor="first_name" className="label pb-2">
                          First Name
                        </label>
                        <input
                          className="form-control input_field form-controls-field"
                          type="text"
                          id="first_name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                          placeholder="First Name"
                          required
                        />
                        {errors.first_name && (
                          <p className="text-danger">{errors.first_name}</p>
                        )}
                      </div>
                      <div className="form-group mb-2 mt-4">
                        <label htmlFor="last_name" className="label pb-2">
                          Last Name
                        </label>
                        <input
                          className="form-control input_field form-controls-field"
                          type="text"
                          id="last_name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                          placeholder="Last Name"
                          required
                        />
                        {errors.last_name && (
                          <p className="text-danger">{errors.last_name}</p>
                        )}
                      </div>
                      <div className="form-group mb-2 mt-4">
                        <label htmlFor="email" className="label pb-2">
                          Email
                        </label>
                        <input
                          className="form-control input_field form-controls-field"
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Email Address"
                          required
                        />
                        {errors.email && (
                          <p className="text-danger">{errors.email}</p>
                        )}
                      </div>
                      <div className="form-group mb-2 mt-4">
                        <label htmlFor="password1" className="pb-2">
                          Password
                        </label>
                        <div className="input-group">
                          <div className="input-group flex-nowrap">
                            <input
                              className="form-control input_field form-controls-field"
                              type={showPassword ? "text" : "password"}
                              id="password1"
                              name="password1"
                              value={formData.password1}
                              onChange={handleChange}
                              placeholder="Password"
                              required
                            />
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? <ShowIcon /> : <HideIcon />}
                            </span>
                          </div>
                          {errors.password1 && (
                            <p className="text-danger">{errors.password1}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group mb-2 mt-4">
                        <label htmlFor="password2" className="pb-2">
                          Confirm Password
                        </label>
                        <div className="input-group">
                          <div className="input-group flex-nowrap">
                            <input
                              className="form-control input_field form-controls-field"
                              type={showConfirmPassword ? "text" : "password"}
                              id="password2"
                              name="password2"
                              value={formData.password2}
                              onChange={handleChange}
                              placeholder="Confirm Password"
                              required
                            />
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {showConfirmPassword ? (
                                <ShowIcon />
                              ) : (
                                <HideIcon />
                              )}
                            </span>
                          </div>
                          {errors.password2 && (
                            <p className="text-danger">{errors.password2}</p>
                          )}
                        </div>
                      </div>

                      <div className="field p-0 mb-2 mt-4">
                        <button
                          className="btn-block w-100 login_button"
                          type="submit"
                        >
                          Sign up
                        </button>
                      </div>

                      <div className="signup-link text-center mt-3">
                        <span>Already have an account? </span>
                        <Link to="/" style={{ color: "#EA454C" }}>
                          Sign in
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 p-0 responsive_div">
            <div className="login-image h-100">
              <img
                src={logo}
                className="img-fluid logo"
                alt="no_img"
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Signup;
