import React, { useState, useEffect, useRef } from "react";
import profile from "../assets/images/avatar.jpg";
import leftarrow from "../assets/images/leftarrow.svg";
import rightarrow from "../assets/images/rightarrow.svg";
import run from "../assets/images/run.svg";
import catlogue from "../assets/images/catlogue.svg";
import data from "../config/data.json";
import Filtergallery from "./Filtergallery";
import { NavLink } from "react-router-dom";
import profilePlaceholder from "../assets/images/dummy2.jpg";
import Faceswap from "./ImageProcessing/Faceswap"; // Import the Faceswap component
import model_image from "../assets/images/dummy2.jpg";
import axios from "axios";
import { Link, Element, scroller } from "react-scroll";
import copy from "../assets/images/copy.svg";
import download from "../assets/images/download.svg";
import Apparels from "./ImageProcessing/Apparels";
import Backgroundswap from "./ImageProcessing/Backgroundswap";

const Proposal = () => {
  const [activeTab, setActiveTab] = useState("face-swap");
  const [tabOptions, setTabOptions] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [showGenerateAnother, setShowGenerateAnother] = useState(false);
  const [showGenerateAnothers, setShowGenerateAnothers] = useState(false); // New state for showing "Generate Another" // New state for showing "Generate Another"
  const [modelData, setModelData] = useState([]); // State to hold model data
  const [modelDatas, setModelDatas] = useState([]); // State to hold model data

  const [generateImage, setGenerateImage] = useState([]);
  const [generateImages, setGenerateImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageIndexs, setCurrentImageIndexs] = useState(0); // New state for the current image index
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showGenerateResponse, setShowGenerateResponse] = useState(false);
  const [buttonDisableds, setButtonDisableds] = useState(true);
  const [showGenerateResponses, setShowGenerateResponses] = useState(false);

  useEffect(() => {
    axios
      .get("https://fake-json-api.mock.beeceptor.com/users")
      .then((res) => setGenerateImage(res.data));
  }, []);

  useEffect(() => {
    axios
      .get("https://fake-json-api.mock.beeceptor.com/users")
      .then((res) => setGenerateImages(res.data));
  }, []);

  const logRef = useRef({});

  useEffect(() => {
    // Initialize tab options data only if not already set
    if (data[activeTab] && !tabOptions[activeTab]) {
      setTabOptions((prevOptions) => ({
        ...prevOptions,
        [activeTab]: data[activeTab].map((item) => ({
          ...item,
          currentValue:
            item.type === "float" ? item.default : item.currentValue,
        })),
      }));
    }
  }, [activeTab, data, tabOptions]);

  useEffect(() => {
    // Check if dropdown values have changed
    const hasChanged = Object.values(tabOptions).some((options) =>
      options.some((item) => item.currentValue !== item.default)
    );
    setButtonDisabled(!hasChanged);
    // setButtonDisableds(!hasChanged);
  }, [tabOptions]);

  const handleDropdownChange = (name, value) => {
    setTabOptions((prevOptions) => {
      const updatedOptions = {
        ...prevOptions,
        [activeTab]: (prevOptions[activeTab] || []).map((item) =>
          item.name === name ? { ...item, currentValue: value } : item
        ),
      };

      if (activeTab === "model-generation") {
        setModelData(updatedOptions["model-generation"]);
      } else if (activeTab === "image") {
        setModelDatas(updatedOptions["image"]);
      }

      const hasChanged = (updatedOptions[activeTab] || []).some(
        (item) => item.currentValue !== item.default
      );

      setButtonDisabled(!hasChanged);
      setShowGenerateResponse(false);

      return updatedOptions;
    });

    setDropdownVisible(null);

    if (!logRef.current[name] || logRef.current[name] !== value) {
      console.log(`Dropdown ${name} changed to: ${value}`);
      logRef.current[name] = value;
    }
  };

  const handleInputChange = (name, value) => {
    setTabOptions((prevOptions) => {
      const updatedOptions = {
        ...prevOptions,
        [activeTab]: (prevOptions[activeTab] || []).map((item) =>
          item.name === name ? { ...item, currentValue: value } : item
        ),
      };

      const hasText = (updatedOptions[activeTab] || []).some(
        (item) => item.currentValue && item.currentValue.trim().length > 0
      );

      setButtonDisableds(!hasText);
      setShowGenerateResponses(false);

      return updatedOptions;
    });
  };

  const handleArrowClick = (name, direction) => {
    setTabOptions((prevOptions) => ({
      ...prevOptions,
      [activeTab]: (prevOptions[activeTab] || []).map((item) => {
        if (item.name === name) {
          if (item.type === "dropdown") {
            const options = item.options;
            const currentIndex = options.indexOf(item.currentValue);
            let newValue;

            if (direction === "left") {
              const newIndex =
                (currentIndex - 1 + options.length) % options.length;
              newValue = options[newIndex];
              setShowGenerateResponse(false);
            } else if (direction === "right") {
              const newIndex = (currentIndex + 1) % options.length;
              newValue = options[newIndex];
              setShowGenerateResponse(false);
            }
            if (!logRef.current[name] || logRef.current[name] !== newValue) {
              console.log(`Selected value is: ${newValue}`);
              logRef.current[name] = newValue;
            }

            return { ...item, currentValue: newValue };
          } else if (item.type === "float") {
            const step = 0.1;
            const [min, max] = item.range;
            const currentValue = parseFloat(item.currentValue || item.default);
            const newValue =
              direction === "left"
                ? Math.max(min, currentValue - step)
                : Math.min(max, currentValue + step);
            if (
              !logRef.current[name] ||
              logRef.current[name] !== newValue.toFixed(1)
            ) {
              console.log(`Selected value is: ${newValue.toFixed(1)}`);
              logRef.current[name] = newValue.toFixed(1);
            }

            return { ...item, currentValue: newValue.toFixed(1) };
          }
        }
        return item;
      }),
    }));
  };

  const toggleDropdownVisibility = (name) => {
    setDropdownVisible(dropdownVisible === name ? null : name);
  };

  const handleGenerateModelClick = () => {
    setShowGenerateAnother(true);
    setShowGenerateResponse(true);
    const modelData = tabOptions["model-generation"] || [];
    setModelData(modelData);
    setButtonDisabled(true);
    // Show the "Generate Another" section
  };

  const handleGenerateModelClicks = () => {
    setShowGenerateAnothers(true);
    setShowGenerateResponses(true);

    setButtonDisableds(true);
    const modelDatas = tabOptions["image"] || [];
    setModelDatas(modelDatas);
    // Show the "Generate Another" section
  };

  const handleGenerateAnotherClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % generateImage.length);
  };

  const handleGenerateAnotherClicks = () => {
    setCurrentImageIndexs(
      (prevIndex) => (prevIndex + 1) % generateImages.length
    );
  };

  // Define the tabs
  const tabs = [
    { id: "face-swap", title: "FACE SWAP" },
    { id: "model-generation", title: "MODEL GENERATION" },
    { id: "image", title: "BACKDROP GENERATION" },
    { id: "pose", title: "POSE", disabled: true },
    { id: "apparels", title: "APPARELS" },
    { id: "background", title: "BACKGROUND SWAP" },
    { id: "video", title: "VIDEO", disabled: true },
    {
      id: "basic-video-settings",
      title: "BASIC VIDEO/SETTINGS",
      disabled: true,
    },
  ];

  return (
    <div
      className="mt-5 pb-5"
      id="bannerSection"
      style={{ position: "relative", paddingTop: "80px" }}
    >
      <div className="container card_bg pb-5">
        <div className="row row-cols-1 row-cols-md-3 g-3">
          <div className="col-md-12 col-lg-12">
            <div className="h-100">
              <div className="d-flex justify-content-end align-items-center gap-3 mt-2 mb-4 flex-md-column flex-lg-row flex-sm-row">
                {activeTab === "model-generation" && (
                  <div>
                    <Link
                      to="response"
                      className="navbar-brand"
                      smooth={true}
                      duration={20}
                      offset={-200}
                      spy={true}
                    >
                      <button
                        className="catalogue_button"
                        onClick={handleGenerateModelClick}
                        disabled={buttonDisabled}
                        style={{
                          backgroundColor: !buttonDisabled
                            ? "#613ab1"
                            : "#d3d3d3",
                          color: !buttonDisabled ? "#fff" : "#888",
                        }}
                      >
                        <img
                          src={run}
                          className="me-2"
                          alt="no_img"
                          style={{ width: "20px", height: "20px" }}
                        />
                        Generate Model
                      </button>
                    </Link>
                  </div>
                )}
              </div>
              {activeTab === "model-generation" && showGenerateResponse && (
                <div className="mt-4 response_data" id="response">
                  {/* <h6 className="text-start mb-3">Response Data</h6> */}

                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                      <div class="modal-content">
                        <div class="modal-header d-flex justify-content-end align-items-center gap-2">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button type="button" class="btn btn-primary">
                            Save
                          </button>
                        </div>
                        <div class="modal-body">
                          <div className="table-responsive">
                            <table className="table table-bordered m-0 p-0">
                              <thead>
                                <tr>
                                  <th>Model</th>
                                  <th>Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                {modelData.map((item, index) => (
                                  <tr key={index}>
                                    {/* <td>{item.label}</td> */}
                                    <td>{item.view_name}</td>
                                    <td>{item.currentValue}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="generate_anothr mb-5">
                    <div class="row justify-content-md-center">
                      <div class="col col-lg-5">
                        <div
                          className="catalogue_section mt-4
                          d-flex justify-content-center align-items-center flex-column"
                        >
                          <div className="card p-2">
                            <button
                              className="copy_button"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              title="response data"
                            >
                              <img
                                src={copy}
                                alt="no_img"
                                style={{ height: "20px", width: "20px" }}
                              />
                            </button>
                            <img
                              src={generateImage[currentImageIndex]?.photo}
                              alt="preview"
                              style={{ width: "100%", minHeight: "100px" }}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <button
                            className="catalogue_button mt-3"
                            onClick={handleGenerateAnotherClick}
                          >
                            <img
                              src={run}
                              alt="no_img"
                              className="me-2"
                              style={{ width: "20px", height: "20px" }}
                            />
                            Generate Another
                          </button>
                          <button className="catalogue_button mt-3">
                            <img
                              src={download}
                              className="me-2"
                              alt="no_img"
                              style={{ width: "20px", height: "20px" }}
                            />
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "image" && (
                <div className="d-flex justify-content-end align-items-center mb-3">
                  <button
                    className="catalogue_button"
                    onClick={handleGenerateModelClicks}
                    disabled={buttonDisableds}
                    style={{
                      backgroundColor: !buttonDisableds ? "#613ab1" : "#d3d3d3",
                      color: !buttonDisableds ? "#fff" : "#888",
                    }}
                  >
                    <img
                      src={run}
                      className="me-2"
                      alt="Generate"
                      style={{ width: "20px", height: "20px" }}
                    />
                    Generate Model
                  </button>
                </div>
              )}

              {activeTab === "image" && showGenerateResponses && (
                <div className="mt-4 mb-5 response_data" id="response">
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                      <div className="modal-content">
                        <div className="modal-header d-flex justify-content-end align-items-center gap-2">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button type="button" className="btn btn-primary">
                            Save
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="table-responsive">
                            <table className="table table-bordered m-0 p-0">
                              <thead>
                                <tr>
                                  <th>Model</th>
                                  <th>Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                {modelDatas.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.view_name}</td>
                                    <td>{item.currentValue}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="generate_anothr mb-3">
                    <div className="row justify-content-md-center">
                      <div className="col col-lg-5">
                        <div className="catalogue_section mt-4 d-flex justify-content-center align-items-center flex-column">
                          <div className="card p-2">
                            <button
                              className="copy_button"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              title="Response Data"
                            >
                              <img
                                src={copy}
                                alt="Copy"
                                style={{ height: "20px", width: "20px" }}
                              />
                            </button>
                            <img
                              src={generateImages[currentImageIndexs]?.photo}
                              alt="Preview"
                              style={{ width: "100%", minHeight: "100px" }}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <button
                            className="catalogue_button mt-3"
                            onClick={handleGenerateAnotherClicks}
                          >
                            <img
                              src={run}
                              alt="Generate Another"
                              className="me-2"
                              style={{ width: "20px", height: "20px" }}
                            />
                            Generate Another
                          </button>
                          <button className="catalogue_button mt-3">
                            <img
                              src={download}
                              className="me-2"
                              alt="Download"
                              style={{ width: "20px", height: "20px" }}
                            />
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="card p-0 card_bg">
                <ul
                  className="nav nav-pills nav-fill flex-wrap proposal_tabs"
                  id="pills-tab"
                  role="tablist"
                >
                  {tabs.map((tab) => (
                    <li className="nav-item" role="presentation" key={tab.id}>
                      <button
                        className={`nav-link ${
                          activeTab === tab.id ? "active" : ""
                        } ${tab.disabled ? "disabled" : ""}`}
                        id={`pills-${tab.id}-tab`}
                        data-bs-toggle="pill"
                        data-bs-target={`#pills-${tab.id}`}
                        type="button"
                        role="tab"
                        aria-controls={`pills-${tab.id}`}
                        aria-selected={activeTab === tab.id ? "true" : "false"}
                        onClick={() => {
                          if (!tab.disabled) {
                            setActiveTab(tab.id);
                          }
                        }}
                      >
                        {tab.title}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {tabs.map((tab) => (
                    <div
                      className={`tab-pane fade ${
                        activeTab === tab.id ? "show active" : ""
                      }`}
                      id={`pills-${tab.id}`}
                      role="tabpanel"
                      aria-labelledby={`pills-${tab.id}-tab`}
                      key={tab.id}
                    >
                      {tab.id === "face-swap" ? (
                        <Faceswap />
                      ) : tab.id === "apparels" ? (
                        <Apparels />
                      ) : tab.id === "background" ? (
                        <Backgroundswap />
                      ) : (
                        <div className="table-responsive">
                          {tabOptions[tab.id] &&
                          tabOptions[tab.id].length > 0 ? (
                            <table className="table table-bordered m-0">
                              <tbody>
                                {tabOptions[tab.id].map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.view_name}</td>
                                    <td>
                                      <div className="d-flex justify-content-between align-items-center">
                                        {item.type === "dropdown" ? (
                                          <>
                                            <div className="item">
                                              <img
                                                src={leftarrow}
                                                alt="Previous"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleArrowClick(
                                                    item.name,
                                                    "left"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="values-container">
                                              {!dropdownVisible ||
                                              dropdownVisible !== item.name ? (
                                                <span
                                                  className="black-content"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    toggleDropdownVisibility(
                                                      item.name
                                                    )
                                                  }
                                                >
                                                  {item.currentValue}
                                                </span>
                                              ) : (
                                                <select
                                                  className="form-select"
                                                  value={item.currentValue}
                                                  onChange={(e) =>
                                                    handleDropdownChange(
                                                      item.name,
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  {item.options.map(
                                                    (option, i) => (
                                                      <option
                                                        key={i}
                                                        value={option}
                                                      >
                                                        {option}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              )}
                                            </div>
                                            <div className="item">
                                              <img
                                                src={rightarrow}
                                                alt="Next"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleArrowClick(
                                                    item.name,
                                                    "right"
                                                  )
                                                }
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <input
                                            type="text"
                                            placeholder="Enter your prompt"
                                            className="form-control"
                                            value={item.currentValue}
                                            onChange={(e) =>
                                              handleInputChange(
                                                item.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center m-2">
                              No data available
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proposal;
