import React, { useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import { UserContext } from "../Auth/UserContext"; // Adjust the path as needed
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import profile_img from "../../assets/images/avatar.jpg";
import userIcon from "../../assets/images/user.svg";
import settings from "../../assets/images/settings.svg";
import billing from "../../assets/images/billing.svg";
import logout from "../../assets/images/logout.svg";
import logo from "../../assets/images/logo.svg";
import menuIcon from "../../assets/images/menu.svg";
import theme from "../../assets/images/theme.svg";
import { logOut } from "../../api/authApi";

const Header = () => {
  const { user, setUser } = useContext(UserContext); // Destructure setUser from context

  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);

  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      toggleDark();
    } else {
      toggleLight();
    }
  };

  const navigate = useNavigate(); // Initialize navigate

  console.log('users: ', user)

  // Updated logout function
  const handleLogout = async () => {
    try {
      await logOut(); 
      navigate("/", { replace: true }); 
    } catch (error) {
      console.error("Logout failed:", error);
      
    }
  };
  
  return (
    <nav className="navbar navbar-expand-md bg-whites navbar-light fixed-top nav-active">
      <div className="container-fluid navbar-page">
        <Link to="/dashboard/home" className="navbar-brand">
          <img
            src={logo}
            alt="logo"
            style={{ height: "auto", width: "35%", objectFit: "contain" }}
            className="m-0"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <img src={menuIcon} className="w-100" alt="menu" />
          </span>
        </button>
        <div
          className="collapse navbar-collapse header_menu"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a
                className="nav-link profile_navlink p-0"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={user?.photo || profile_img}
                  alt="Avatar"
                  className="avatar"
                />
              </a>
              <ul className="dropdown-menu dropdown-menu-end dashboard_dropdown">
                <div className="media d-flex justify-content-start align-items-center">
                  <img
                    className="me-3 ms-3"
                    src={user?.photo || profile_img}
                    alt="User"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="media-body me-3 mt-2">
                    <h5 className="mt-0 m-auto profile_heading">
                      {user?.first_name || "Guest"}
                    </h5>
                    <h6 className="email-text">{user?.email || "No Email"}</h6>
                  </div>
                </div>
                <hr className="mt-2 mb-1" />
                <li>
                  <Link className="dropdown-item" to="/dashboard/profile">
                    <img src={userIcon} alt="My Profile" />
                    My Profile
                  </Link>
                </li>
                <li
                  className="d-flex justify-content-between align-items-center"
                  style={{ padding: "4px 16px" }}
                >
                  <div className="d-flex align-items-center me-4">
                    <img src={theme} alt="no_img" className="me-2" />
                    <span className="theme_heading">Theme</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      checked={mode === "dark-mode"}
                      onChange={handleThemeToggle}
                    />
                    <label htmlFor="checkbox" className="checkbox-label">
                      <i className="fas fa-moon"></i>
                      <i className="fas fa-sun"></i>
                      <span className="ball"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <Link className="dropdown-item" to="/dashboard/accounts">
                    <img src={settings} alt="Account Settings" />
                    Account Settings
                  </Link>
                </li>

                <li>
                  <Link className="dropdown-item" to="#/">
                    <img src={billing} alt="Billing & Crediting" />
                    Billing & Crediting
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/"
                    style={{ color: "#FA615C" }}
                    onClick={handleLogout} // Call logout handler
                  >
                    <img src={logout} alt="Logout" />
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
