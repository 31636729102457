// src/api/userApi.js
import axiosInstance from "./axiosConfig.jsx";
import { taskStatus } from "../config/constants.jsx";
import { sleep } from "../utils/utils.jsx";

async function fetchImageAsBlob(imageUrl) {
  const response = await axiosInstance.get(imageUrl, {
    responseType: "blob",
  });
  return response.data;
}

export const postFaceSwap = async (face_image_url, model_image_path) => {
  try {
    const formData = new FormData();
    const faceImageBlob = await fetchImageAsBlob(face_image_url);
    const faceImageName = face_image_url.split("/").pop();

    formData.append("face_image_path", faceImageBlob, faceImageName);
    formData.append("model_image_path", model_image_path);
    formData.append("model_name", "face_swap");

    const response = await axiosInstance.post("/api/ai/models/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response ? error.response.data : error;
  }
};

export const getTaskStatus = async (task_id) => {
  try {
    const response = await axiosInstance.get(
      `/api/ai/models/${task_id}/status`
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const pollForTaskFinish = async (
  task_id,
  wait_time = 2000,
  max_retry = 100,
  incomplete_statuses = [
    taskStatus.QUEUED,
    taskStatus.STARTING,
    taskStatus.IN_PROCESS,
  ],
  completed_statuses = [
    taskStatus.FAILED,
    taskStatus.EMPTY_OUTPUT,
    taskStatus.SUCCESS,
  ]
) => {
  let statusObj = await getTaskStatus(task_id);
  let iterationCount = 1;
  console.log("Iteration:", iterationCount, statusObj);
  while (incomplete_statuses.includes(statusObj.status)) {
    statusObj = await getTaskStatus(task_id);
    iterationCount += 1;
    console.log("Iteration:", iterationCount, statusObj);
    if (iterationCount > max_retry) {
      throw new Error("Max retry for status poll failed!");
    }
    await sleep(wait_time);
  }
  if (completed_statuses.includes(statusObj.status)) {
    return statusObj;
  } else {
    throw new Error(`Invalid status code: ${statusObj.status}`);
  }
};
