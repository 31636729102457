export const PopupBox = ({
  warningMessage,
  alertVisible,
  disableFunction = null,
}) => {
  return (
    <div
      style={{ zIndex: "9999" }}
      className={`alert alert-warning alert-dismissible fade show position-fixed top-0 end-0 m-3 ${
        alertVisible ? "alert-show" : "alert-hide"
      }`}
      role="alert"
    >
      {warningMessage}
      <button
        type="button"
        className="btn-close"
        onClick={() => {
          if (disableFunction) {
            disableFunction();
          }
        }}
        aria-label="Close"
        style={{ border: "none", height: "15px", width: "15px" }}
      ></button>
    </div>
  );
};
