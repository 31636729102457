import React, { Fragment } from "react";
import HeadImage from "../assets/images/Head.svg";
import SwitchImage from "../assets/images/switch.svg";
import SkinToneImage from "../assets/images/shopping.svg";
import ExerciseImage from "../assets/images/Exercise.svg";
import { Link, NavLink } from "react-router-dom";

const BannerCard = ({ id, imageSrc, title, description, link }) => {
  let imageUrl = "";
  switch (imageSrc) {
    case "Head":
      imageUrl = HeadImage;
      break;
    case "Switch":
      imageUrl = SwitchImage;
      break;
    case "Skin Tone":
      imageUrl = SkinToneImage;
      break;
    case "Exercise":
      imageUrl = ExerciseImage;
      break;
    default:
      break;
  }

  const colorMapping = {
    1: "var(--color1)",
    2: "var(--color2)",
    3: "var(--color3)",
    4: "var(--color4)",
  };

  const backgroundColor = colorMapping[id] || "var(--color1)";

  return (
    <Fragment>
      <div className="col-md-6">
        <div
          className="card dashboard_card h-100"
          style={{ background: backgroundColor }}
        >
          <NavLink to={link} className="text-decoration-none text-dark">
            <div>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="uxdrivern_section designcolor_1">
                    <div className="uxdriven_img">
                      <img src={imageUrl} alt={title} />
                    </div>
                  </div>
                </div>
                <h6 className="flex-grow-1 ms-3 card_title">{title}</h6>
              </div>
              <p className="card-text mb-3">{description}</p>
            </div>
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
};

export default BannerCard;
