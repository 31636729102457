import React, { useState, useEffect, useRef } from "react";
import profile from "../../assets/images/avatar.jpg";
import leftarrow from "../../assets/images/leftarrow.svg";
import rightarrow from "../../assets/images/rightarrow.svg";
import run from "../../assets/images/run.svg";
import catlogue from "../../assets/images/catlogue.svg";
import data from "../../config/data.json";
import Filtergallery from "../Filtergallery";
import { NavLink } from "react-router-dom";
import profilePlaceholder from "../../assets/images/dummy2.jpg";
import edit from "../../assets/images/edit.svg";

const Profile = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const logRef = useRef({});

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <div
      className="mt-5 pb-5"
      id="bannerSection"
      style={{ position: "relative" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="text-center text-white pt-5 mb-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mt-3 mb-3">
                <li className="breadcrumb-item">
                  <NavLink to="/dashboard/home">
                    <h2 className="p-0 m-0" style={{ lineHeight: "15px" }}>
                      Dashboard
                    </h2>
                  </NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container card_bg mt-2 pb-5 profile_section">
        <h4 className="pt-3 settings_heading">My Profile</h4>
        <p></p>
        <hr />
        <div className="row row-cols-1 row-cols-md-3 g-3">
          <div className="col-md-6 col-lg-3">
            <div className="card h-100 card_bg">
              <div>
                <div
                  className="p-0
                d-flex justify-content-center align-items-center 
                flex-column"
                >
                  <img
                    src={selectedImage || profilePlaceholder}
                    alt="preview"
                    style={{
                      width: "220px",
                      height: "220px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="upload-section mt-3">
                    <div class="variants">
                      <div class="file file--upload">
                        <label for="input-file" style={{ border: "0px" }}>
                          <img src={edit} alt="no_img" />
                          Update Photo
                        </label>
                        <input
                          id="input-file"
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-9">
            <div className="card card_bg h-100">
              <div className="p-0 profile_section">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="first_name" class="form-label">
                      Frist Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="first_name"
                      value="santosh guravana"
                      readOnly
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="email" class="form-label">
                      email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      value="abc@example.com"
                      readOnly
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <label for="number" class="form-label">
                      Phone number
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      id="number"
                      placeholder="enter phone number"
                    />
                  </div>

                  <div class="col-12 d-flex justify-content-center align-items-center">
                    <button type="submit" class="catalogue_button w-20 mt-3">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
