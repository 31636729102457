import React, { useState, useEffect } from "react";
import profilePlaceholder from "../../assets/images/dummy2.jpg";
import run from "../../assets/images/run.svg";
import download from "../../assets/images/download.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { get_face_images } from "../../api/statics.jsx";
import { postFaceSwap, pollForTaskFinish } from "../../api/modelsApi.jsx";
import { taskStatus } from "../../config/constants.jsx";

const Faceswap = () => {
  const [selectedImage, setSelectedImage] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [appliedFilter, setAppliedFilter] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");
  const [images, setImages] = useState([]);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [taskId, setTaskId] = useState(null);

  //   const [warningMessage, setWarningMessage] = useState("");
  //   const [alertVisible, setAlertVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (alertVisible) {
      timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000); // Hide alert after 5 seconds
    }
    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, [alertVisible]);

  // Fetch images from the API
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const image_data_list = await get_face_images();
        const image_url_list = image_data_list.map((image) => image.file);
        setImages(image_url_list); // Use all images
      } catch (error) {
        console.error("Error fetching images:", error);
        setWarningMessage("Failed to load images.");
        setAlertVisible(true);
      }
    };

    fetchImages();
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 5, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 5, images.length - 5));
  };

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      setSelectedImage({
        newImage: newImage,
        newImageUrl: URL.createObjectURL(newImage),
      });
      setSelectedFilter(null);
      setAppliedFilter(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
    }
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setWarningMessage("");
    setAlertVisible(false);
  };

  const handleRun = async () => {
    const faceSwapResponse = await postFaceSwap(
      selectedFilter,
      selectedImage.newImage
    );
    const _taskId = faceSwapResponse.task_id;
    setTaskId(_taskId);
    let statusObj = await pollForTaskFinish(_taskId);
    if (
      statusObj.status === taskStatus.FAILED ||
      statusObj.status === taskStatus.EMPTY_OUTPUT
    ) {
      alert("failed to process image!");
    } else if (statusObj.status === taskStatus.SUCCESS) {
      setAppliedFilter(statusObj.output);
    } else {
      throw new Error(`Unknown task status: ${statusObj.status}`);
    }
  };

  const handleDownload = async () => {
    if (isDownloadReady) {
      try {
        const proxyUrl = "https://api.allorigins.win/get?url=";
        const response = await fetch(
          proxyUrl + encodeURIComponent(appliedFilter)
        );

        if (!response.ok) throw new Error("Failed to fetch image");

        const data = await response.json();
        const blobResponse = await fetch(data.contents);

        if (!blobResponse.ok) throw new Error("Failed to fetch image blob");

        const blob = await blobResponse.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "response.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);

        setWarningMessage("Image downloaded successfully!");
        setIsDownloadReady(false);
        setAlertVisible(true);
      } catch (error) {
        setWarningMessage(
          "Failed to load the image for download. Please check the image URL."
        );
        setAlertVisible(true);
        console.error("Download failed:", error);
      }
    } else {
      setWarningMessage(
        "No processed image ready for download. Please apply a filter first."
      );
      setAlertVisible(true);
    }
  };

  return (
    <div
      className="mt-5 pb-5"
      id="bannerSection"
      style={{ position: "relative" }}
    >
      <div className="container  mt-3">
        <div className="row row-cols-1 row-cols-md-3 g-4 d-flex justify-content-center align-items-center">
          <div className="col-md-6 col-lg-3 d-flex flex-column align-items-center">
            <div className="card h-100 card_bg w-100">
              <div className="p-0 d-flex justify-content-center align-items-center flex-column">
                <img
                  src={selectedImage.newImageUrl || profilePlaceholder}
                  alt="preview"
                  className="same-size-image img-fluid"
                />
                <div className="upload-section mt-3">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          {appliedFilter && (
            <div className="col-md-6 col-lg-3 d-flex flex-column align-items-center">
              <div className="card h-100 card_bg w-100">
                <div className="p-0">
                  <img
                    src={appliedFilter}
                    alt="filtered"
                    className="same-size-image img-fluid response_img"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {selectedImage && (
          <div className="model_information mt-5">
            <div className="row justify-content-md-center mt-3">
              <div className="col-lg-12">
                <h6
                  className="mb-5 disable_heading text-center"
                  style={{ color: "#613ab1" }}
                >
                  Face Filters
                </h6>
                <div className="filter_images">
                  <button
                    className="nav-button"
                    onClick={handlePrevious}
                    disabled={currentIndex === 0}
                  >
                    <span className="carousel_symbols"> &lt;</span>
                  </button>
                  {images
                    .slice(currentIndex, currentIndex + 5)
                    .map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        className="img-thumbnail"
                        alt={`Filter ${currentIndex + index + 1}`}
                        onClick={() => handleFilterSelect(img)}
                        style={{
                          cursor: "pointer",
                          border:
                            selectedFilter === img
                              ? "3px solid #613ab1"
                              : "1px solid #DCDCDC",
                        }}
                      />
                    ))}
                  <button
                    className="nav-button"
                    onClick={handleNext}
                    disabled={currentIndex + 5 >= images.length}
                  >
                    <span className="carousel_symbols"> &gt;</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-3 mt-2 mb-4 flex-md-column flex-lg-row flex-sm-row">
              <div>
                <button
                  className="catalogue_button"
                  onClick={handleRun}
                  disabled={!selectedFilter}
                  style={{
                    backgroundColor: selectedFilter ? "#613ab1" : "#d3d3d3",
                    color: selectedFilter ? "#fff" : "#888",
                  }}
                >
                  <img
                    src={run}
                    alt="run"
                    className="me-2"
                    style={{ width: "20px", height: "20px" }}
                  />
                  Run
                </button>
              </div>

              <div>
                <button
                  className="catalogue_button"
                  onClick={handleDownload}
                  disabled={!isDownloadReady}
                  style={{
                    backgroundColor: isDownloadReady ? "#613ab1" : "#d3d3d3",
                    color: isDownloadReady ? "#fff" : "#888",
                  }}
                >
                  <img
                    src={download}
                    alt="download"
                    className="me-2"
                    style={{ width: "20px", height: "20px" }}
                  />
                  Download
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {alertVisible && warningMessage && (
        <>
          <div
            style={{ zIndex: "9999" }}
            className={`alert alert-warning alert-dismissible fade show position-fixed top-0 end-0 m-3 ${
              alertVisible ? "alert-show" : "alert-hide"
            }`}
            role="alert"
          >
            {warningMessage}
            <button
              type="button"
              className="btn-close"
              onClick={() => setAlertVisible(false)}
              aria-label="Close"
              style={{ border: "none", height: "15px", width: "15px" }}
            ></button>
          </div>
        </>
      )}
    </div>
  );
};

export default Faceswap;
