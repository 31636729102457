import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import instagram from "../../assets/images/instagram.svg";
import twitter from "../../assets/images/twitter.svg";
import google from "../../assets/images/googleicon.svg";
import linkedin from "../../assets/images/linkedin.svg";

const Accounts = () => {
  return (
    <div
      className="mt-5 pb-5"
      id="bannerSection"
      style={{ position: "relative" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="text-center text-white pt-5 mb-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mt-3 mb-3">
                <li className="breadcrumb-item">
                  <NavLink to="/dashboard/home">
                    <h2 className="p-0 m-0" style={{ lineHeight: "15px" }}>
                      Dashboard
                    </h2>
                  </NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Account
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container card_bg mt-2 pb-5 profile_section">
        <h4 className="pt-3 settings_heading">Accounts</h4>
        <hr />
        <div className="row">
          <div className="col-12">
            <div className="h-100">
              <div className="p-0 profile_section">
                <form class="row g-3">
                  <div class="col-md-8">
                    <label for="first_name" class="form-label">
                      Give feedback
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      id="first_name"
                      rows={6}
                      cols={6}
                    />
                    <div class="d-flex justify-content-end align-items-center">
                      <button type="submit" class="catalogue_button w-20 mt-3">
                        Send
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label for="email" class="form-label">
                      About
                    </label>
                    ß
                  </div>

                  <div class="col-md-12">
                    <label for="email" class="form-label">
                      Follow us:
                    </label>
                    <p className="mb-2">Devnex Technologies</p>
                    <div className="socialmedia_icons d-flex gap-2">
                      <NavLink to="#/">
                        <img src={instagram} alt="instagram" />
                      </NavLink>
                      <NavLink to="#/">
                        <img src={twitter} alt="twitter" />
                      </NavLink>
                      <NavLink to="#/">
                        <img src={google} alt="google" />
                      </NavLink>
                      <NavLink to="#/">
                        <img src={linkedin} alt="linkedin" />
                      </NavLink>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
