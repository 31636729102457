import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <div className="fixed-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="footer_center_logo" style={{ textAlign: "center" }}>
              <div className="copy_right_section">
                <h2 className="copyright_heading mt-2 text-start">
                  Copyright © Devnex 2024. All rights reserved
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
